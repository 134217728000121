<script>
  export let visible = false,
    style = "grey";

  import Overlay from "./Overlay.svelte";
</script>

<Overlay {visible} {style}>
  <div class="loader">
    <div class="logo" class:visible>
      <img src="/images/homepage-logo-small.png" alt="Logo" />
    </div>
    <slot />
  </div>
</Overlay>

<style>
  @import "../styles/variables.css";

  .loader {
    position: relative;
    z-index: var(--overlay-fg-layer);

    display: flex;
    align-items: center;
  }

  .logo {
    filter: blur(10px);
    opacity: 0;
    transition: opacity 500ms, filter 500ms;
  }

  .logo.visible {
    filter: blur(0);
    opacity: 1;
    animation-name: logo;
    animation-duration: 5s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    animation-delay: 500ms;
  }

  @keyframes logo {
    0% {
      transform: scale(1);
    }

    100% {
      transform: scale(1.1);
    }
  }
</style>
